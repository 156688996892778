import React from "react"
import BuildIcon from "./build-icon"
import "./contributions.css"

export const Contributions = ({ children }) => (
  <ul className="contributions">{children}</ul>
)

export const Contribution = ({ children }) => (
  <li className="contributions__entry">
    <BuildIcon />
    {children}
  </li>
)

import React, { useState, useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Name from "../components/name"
import {
  Card,
  CardContent,
  CardHeading,
  CardParagraph,
  CardSubHeading,
} from "../components/card"
import { Timeline, TimelineEntry } from "../components/timeline"
import Me from "../components/me"
import "./cv.css"
import { Contributions, Contribution } from "../components/contributions"

const CvPage = () => {
  const [hasAnimatedSplash, setHasAnimatedSplash] = useState(false)

  useEffect(() => {
    setHasAnimatedSplash(true)
  }, [])

  return (
    <Layout>
      <SEO title="CV" theme="light" />
      <div className="cv-grid">
        <header className="header inverse-color">
          <div className="header__content cv-grid__content">
            <div className="header__image">
              <Me />
            </div>
            <div className="header__text">
              <div className="header__name">
                <h1>
                  <Name />
                </h1>
              </div>
              <div className="header__desc">
                A software engineer with a passion for the web
              </div>
            </div>
          </div>
        </header>

        <div
          className={`cv-grid__content ${
            hasAnimatedSplash ? "animate-cards" : ""
          }`}
        >
          <Card color="blue">
            <CardHeading className="card__heading">About me</CardHeading>
            <CardContent>
              <CardParagraph>
                Hello, I'm Jesper Petersson! I build software for the web, and I
                am currently working at Amazon in Seattle.
              </CardParagraph>
              <CardParagraph>
                If you want to get in touch, just ping me on{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/peterssonjesper"
                >
                  Twitter
                </a>{" "}
                <span className="print-only"> (@PeterssonJesper)</span> or{" "}
                <a href="mailto:jesper@jpetersson.se">drop me an email</a>
                <span className="print-only"> (jesper@jpetersson.se)</span>.
              </CardParagraph>
            </CardContent>
          </Card>

          <Card color="green">
            <CardHeading className="card__heading">Education</CardHeading>
            <CardContent>
              <Timeline>
                <TimelineEntry>
                  <CardSubHeading>
                    2008-2012: Linköping Institute of Technology
                  </CardSubHeading>
                  <p>
                    <b>Program:</b> Master of science in computer engineering (5
                    years)
                  </p>
                  <p>
                    <b>Profile:</b> Computer systems
                  </p>
                  <p>
                    <b>Master thesis:</b> Designing an architecture for
                    single-page applications in Javascript and HTML5
                  </p>
                  <p>
                    <b>Average grade:</b> 4.6 (out of 5)
                  </p>
                </TimelineEntry>
                <TimelineEntry isLast={true}>
                  <CardSubHeading>
                    2005-2008: Upper secondary school, Forsmarks Skola
                  </CardSubHeading>
                  <p>
                    <b>Program:</b> Natural science, with focus on software
                    development
                  </p>
                  <p>
                    <b>Average grade:</b> 19.6 (out of 20)
                  </p>
                </TimelineEntry>
              </Timeline>
            </CardContent>
          </Card>

          <Card color="red">
            <CardHeading className="card__heading">Work Experience</CardHeading>
            <CardContent>
              <Timeline>
                <TimelineEntry>
                  <CardSubHeading>
                    2016 - present: Software Development Engineer, Amazon,
                    Seattle
                  </CardSubHeading>
                  <CardParagraph>
                    Amazon is the world's largest internet-based retailer. I
                    build systems and tools to manage the million of categories
                    and filters that are powering Amazon's browse and search
                    experiences across the globe. My role as an engineer covers
                    the stack all the way from system design and databases to
                    building user interfaces for internal tooling. I have a
                    leading role in my team where I drive best engineering
                    practices and mentor new team members.
                  </CardParagraph>

                  <CardParagraph>
                    <b>Key contributions</b>
                    <Contributions className="contributions">
                      <Contribution>
                        I was the lead frontend engineer on a very successful
                        but large project (2 years of development across 5 teams
                        and 2 time zones).
                      </Contribution>
                      <Contribution>
                        I built a low-latency system for previewing products in
                        a category before publishing a change to customers. This
                        was previously an unsolved problem due to its scale
                        (billions of products can be affected by a change).
                      </Contribution>
                      <Contribution>
                        I developed an internal component library and design
                        system to drive UX consistency across applications. It
                        is now used by 10 other teams.
                      </Contribution>
                    </Contributions>
                  </CardParagraph>

                  <CardParagraph>
                    <b>Technologies:</b> Java, Spring MVC, Javascript, React,
                    Redux, Graph QL, Apollo, Typescript, SCSS, Dynamo DB, RDS,
                    S3, Neptune, Redshift, ElastiCache, EC2, Lambda, CloudFront,
                    Route 53, API Gateway, SQS, SNS, Kinesis, Athena, EMR,
                    Elastic Search, Spark, Git
                  </CardParagraph>
                  <div className="page-break"></div>
                </TimelineEntry>

                <TimelineEntry>
                  <CardSubHeading>
                    2013 - present: Software engineer, GeoGuessr
                  </CardSubHeading>
                  <CardParagraph>
                    <a
                      href="https://www.geoguessr.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      GeoGuessr
                    </a>{" "}
                    is a web-based geography game where players are randomly
                    dropped somewhere in the world, and the task is to guess the
                    location using clues visible in Google Street View. I am the
                    lead frontend engineer in a team of 6 people. The game is
                    receiving a lot of attention{" "}
                    <a
                      href="https://twitter.com/search?q=geoguessr&src=typed_query&f=live"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      in
                    </a>{" "}
                    <a
                      href="https://www.youtube.com/results?search_query=geoguessr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      social
                    </a>{" "}
                    <a
                      href="https://www.reddit.com/r/geoguessr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      media
                    </a>{" "}
                    and have millions of players each month. Special editions of
                    the game has been built for customers such as BBC,
                    Lufthansa, United Airlines, Skoda and Heineken.{" "}
                  </CardParagraph>

                  <CardParagraph>
                    <b>Key contributions</b>
                    <Contributions className="contributions">
                      <Contribution>
                        I built large portions of the UI (start page, signup,
                        browsing pages, the game itself, etc.).
                      </Contribution>
                      <Contribution>
                        I integrated with a payment platform (Stripe) to offer a
                        paid version of the game.
                      </Contribution>
                    </Contributions>
                  </CardParagraph>

                  <CardParagraph>
                    <b>Technologies:</b> C#, Nancy, Mongo DB, Dynamo DB,
                    CloudFront, React, Webpack, Less, Grunt, Git
                  </CardParagraph>
                </TimelineEntry>

                <TimelineEntry>
                  <CardSubHeading>
                    2014 - 2016: Software engineer, Booli, Stockholm
                  </CardSubHeading>
                  <CardParagraph>
                    <a
                      href="http://www.booli.se/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Booli.se
                    </a>{" "}
                    is a real estate search engine where people can find houses
                    for sale in Sweden. While Booli has a lot of data about the
                    real estate market, they struggled with presenting it in a
                    way that was easy to grasp. By putting the user in the
                    centre of the design process, I found ways to present the
                    right information at the right time.
                  </CardParagraph>

                  <CardParagraph>
                    <b>Key contributions</b>
                    <Contributions className="contributions">
                      <Contribution>
                        I decoupled the user interface from a monolithic PHP
                        application into a new service written in Go.
                      </Contribution>
                      <Contribution>
                        I designed and implemented the algorithms behind Booli's
                        price estimation engine for apartments and houses. It
                        outperformed the competitors and is today still one of
                        Booli's most beloved features.
                      </Contribution>
                      <Contribution>
                        I built the start page, search results, map view and
                        detail page.
                      </Contribution>
                    </Contributions>
                  </CardParagraph>

                  <CardParagraph>
                    <b>Technologies:</b> Go, Revel, PHP, MySQL, Javascript,
                    React, Backbone.js, Flux, Require.js, Jasmine, SCSS, Grunt,
                    Git
                  </CardParagraph>
                </TimelineEntry>

                <TimelineEntry>
                  <CardSubHeading>
                    2012-2014: Technical consultant, Valtech Sweden, Stockholm
                  </CardSubHeading>
                  <CardParagraph>
                    Valtech is one of the leading web consultancy firms in
                    Stockholm. During my time at Valtech I worked with the
                    following clients:
                  </CardParagraph>

                  <CardParagraph>
                    <b>SVT, the Swedish national TV broadcaster:</b> I was
                    responsible for building the interactive user experience for
                    their new video-on-demand service for kids:{" "}
                    <a
                      href="https://www.svt.se/barnkanalen/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Barnkanalen play
                    </a>
                    . The website was later released as a wrapper in the App
                    Store where it reached first place in several categories. It
                    got nominated to the Swedish design awards for the best
                    service on tablet devices the same year.
                  </CardParagraph>

                  <CardParagraph>
                    <b>Ongame, an online gaming platform:</b> I was a backend
                    engineer on a team who built Ongame's new white label
                    platform for casino and poker sites.
                  </CardParagraph>
                </TimelineEntry>

                <TimelineEntry>
                  <CardSubHeading>
                    2011: Summer intern, Zenterio AB, Linköping
                  </CardSubHeading>
                  <CardParagraph>
                    I developed the software for a microcontroller that managed
                    the power of a set-top-box in order to make it more power
                    efficient. To cope with the precise timing requirements of
                    reprogramming the microcontroller I built a kernel module
                    for the main board to avoid the threads to context switch in
                    the middle of an update.
                  </CardParagraph>

                  <CardParagraph>
                    <b>Technologies:</b> Embedded systems, C, Linux kernel, C++
                  </CardParagraph>
                </TimelineEntry>

                <TimelineEntry isLast={true}>
                  <CardSubHeading>
                    2008-2012: Founder and developer, Doubleint, Linköping
                  </CardSubHeading>
                  <CardParagraph>
                    While studying to my computer science degree I started a
                    consulting firm to help small and medium-sized companies
                    with their external websites and back office tooling.
                  </CardParagraph>
                </TimelineEntry>
              </Timeline>
            </CardContent>
          </Card>

          <div className="page-break"></div>

          <Card color="blue">
            <CardHeading className="card__heading">
              Non-programming languages
            </CardHeading>
            <CardContent>
              <CardParagraph>
                <b>English: </b> Strong
              </CardParagraph>
              <CardParagraph>
                <b>Swedish: </b> Native
              </CardParagraph>
            </CardContent>
          </Card>

          <Card color="green">
            <CardHeading className="card__heading">
              Computer related
            </CardHeading>
            <CardContent>
              <CardSubHeading>Programming languages</CardSubHeading>
              <CardParagraph>
                Proficient: Java and Javascript <br />
                Prior experience: C, C++, C#, Go, Python, PHP, Ruby, Bash
              </CardParagraph>

              <CardSubHeading>Web</CardSubHeading>
              <CardParagraph>
                React, Redux, Graph QL, Apollo, Typescript, Backbone, Jasmine,
                Mocha, Sinon, SCSS/Less/CSS, RWD, Webpack, Spring MVC, Nancy,
                Revel
              </CardParagraph>

              <CardSubHeading>Compute, Storage & Networking</CardSubHeading>
              <CardParagraph>
                EC2, Lambda, Dynamo DB, S3, Mongo DB, Redshift, ElastiCache,
                RDS, MySQL, Neptune, Elastic Search, API Gateway, CloudFront,
                SQS, SNS, Kinesis, Athena, EMR, Spark
              </CardParagraph>

              <CardSubHeading>Methodologies</CardSubHeading>
              <CardParagraph>
                Scrum, XP, Kanban, User story mapping, TDD
              </CardParagraph>

              <CardSubHeading>Misc</CardSubHeading>
              <CardParagraph>
                GNU/Linux, Mac OS X, Vim, versioning (Git, Mercurial,
                Subversion), Sketch
              </CardParagraph>
            </CardContent>
          </Card>
        </div>
      </div>
    </Layout>
  )
}

export default CvPage

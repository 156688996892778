import React from "react"
import "./card.css"

export const Card = ({ children, color = "blue" }) => (
  <article className={`card card--${color}`}>{children}</article>
)
export const CardHeading = ({ children }) => (
  <h2 className="card__heading">{children}</h2>
)
export const CardSubHeading = ({ children }) => (
  <h3 className="card__sub-heading">{children}</h3>
)
export const CardContent = ({ children }) => (
  <div className="card__content">{children}</div>
)
export const CardParagraph = ({ children }) => (
  <p className="card__paragraph">{children}</p>
)

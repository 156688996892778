import React from "react"
import "./timeline.css"
import CheckIcon from "./check-icon"

export const Timeline = ({ children }) => (
  <div className="timeline">{children}</div>
)
export const TimelineEntry = ({ children, isLast = false }) => (
  <>
    <div className={`timeline__time ${isLast ? "timeline__time--last" : ""}`}>
      <div className="timeline__circle">
        <CheckIcon />
      </div>
      <div className="timeline__line"></div>
    </div>
    <div className="timeline__entry">{children}</div>
  </>
)

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "me.jpg" }) {
        childImageSharp {
          fixed(width: 196, height: 196, quality: 100, jpegProgressive: false) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <Img
      fixed={data.image.childImageSharp.fixed}
      objectFit="cover"
      objectPosition="50% 50%"
      alt="Jesper Petersson"
    />
  )
}

export default Image
